<template>
  <BasicModal title="Secret Settings Modal" cancelTitle="Close" :showActionButton="false">
    <div class="flex flex-col gap-2">
      <button class="small" @click="copyAuthToken()">Copy auth token</button>
      <button class="small" @click="copyRefreshToken()">Copy refresh token</button>
      <button class="small" @click="setBadAuthtoken()">Set bad auth token</button>
      <hr>
      <button class="small" @click="resetUserFlag('hasDismissedWelcomeModal')">Reset hasDismissedWelcomeModal flag</button>
      <button class="small" @click="resetUserFlag('hasAnsweredNftMediaCaching')">Reset hasAnsweredNftMediaCaching flag</button>
      <button class="small" @click="resetUserFlag('hasDismissedSmartContractAnnouncement')">Reset hasDismissedSmartContractAnnouncement flag</button>
      <hr>
      <button class="small" @click="resetUserFlag('hasDismissedPatchesPausedModal')">Reset hasDismissedPatchesPausedModal flag</button>
      <button class="small" @click="resetUserFlag('hasDismissedFailedPaymentBanner')">Reset hasDismissedFailedPaymentBanner flag</button>
      <button class="small" @click="resetUserFlag('hasDismissedPendingDowngradeBanner')">Reset hasDismissedPendingDowngradeBanner flag</button>
      <button class="small" @click="resetUserFlag('hasDismissedActionLimitWarningBanner')">Reset hasDismissedActionLimitWarningBanner flag</button>
      <hr>
      <form @submit.prevent="setUserPlanStats">
        <FormInput :formName="formName" fieldName="credits" />
        <FormInput :formName="formName" fieldName="usage" />
        <FormInput :formName="formName" fieldName="overages" />
        <button
          @click.prevent
          class="small primary"
          :disabled="!isFormValid"
        >
          Update
        </button>
      </form>
    </div>
  </BasicModal>
</template>

<script>

  import { mapState } from 'vuex'

  import useForm from '@/composables/useForm'

  import FormInput from '@/components/forms/FormInput.vue'
  import BasicModal from '@/components/modals/_BasicModal.vue'

  export default {
    components: {
      FormInput,
      BasicModal,
    },
    setup() {

      // data
      const formName = 'secretSettingsForm'

      // composables
      const { form, isFormValid } = useForm({ formName })

      return {
        form,
        formName,
        isFormValid,
      }

    },
    computed: {
      ...mapState('user', [
        'userPlan',
        'authToken',
        'refreshToken',
        'userPlanStats',
      ]),
    },
    watch: {
      'form.fields.credits.value': function creditsValue(newValue) {
        this.$store.commit('forms/SET_FIELD_VALUE', {
          fieldName: 'usage',
          formName: this.formName,
          newValue: this.userPlan.actionEventLimit - Number.parseInt(newValue, 10),
        })
      },
    },
    created() {
      this.$store.commit('forms/SET_FIELD_VALUE', {
        fieldName: 'usage',
        formName: this.formName,
        newValue: this.userPlanStats.usage,
      })
      this.$store.commit('forms/SET_FIELD_VALUE', {
        fieldName: 'credits',
        formName: this.formName,
        newValue: this.userPlanStats.credits,
      })
      this.$store.commit('forms/SET_FIELD_VALUE', {
        fieldName: 'overages',
        formName: this.formName,
        newValue: this.userPlanStats.overages,
      })
    },
    methods: {
      copyAuthToken() {
        navigator.clipboard.writeText(this.authToken)
      },
      copyRefreshToken() {
        navigator.clipboard.writeText(this.refreshToken)
      },
      setBadAuthtoken() {
        this.$store.commit('api/SET_DISPATCH_AUTH_TOKEN', Math.random())
      },
      resetUserFlag(flagName) {
        this.$store.dispatch('user/UPDATE_USER_FLAGS', { [flagName]: false })
      },
      setUserPlanStats() {
        this.$store.commit('user/SET_USER_PLAN_STATS', {
          usage: this.form.fields.usage.value,
          credits: this.form.fields.credits.value,
          overages: this.form.fields.overages.value,
        })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  hr
    @apply my-6
    @apply border-gray-500

</style>
